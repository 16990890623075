import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import ChartBase from './ChartBase';
export const _frontmatter = {
  "title": "내가 속한 조직의 비리를 고발할 수 있나?",
  "slug": "data2",
  "date": "2022-02-21T15:00:00.000Z",
  "thumb": "../images/thumbs/thumb02.jpg",
  "featuredImg": "../images/featured/featured02.jpg",
  "summary": "공익 제보에 관한 한국인 의 인식은 어떨까? 공익 제보가 정착되기 어렵게 만드는 요인은 무엇일까?",
  "tags": ["정책", "비리", "조직", "공익제보"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`<세계일보> 특별취재팀은 1990년 이후 이뤄진 공익 제보 102건을 추적해 조사한 후 공익 제보는 작고 더디더라도 결국 사회를 바꾼다”는 결론을 내렸다. 용기 있는 '내부 고발은 끔찍한 사고를 줄이고, 일상을 파괴하는 사악한 시도를 막아주며, 개인과 사회의 자원을 절약하며, 수많은 법적 분쟁과 사회적 갈등을 피할 수 있게 도와 준다는 것이 공익 제보의 효과에 대한 선진국의 인식이다.`}<br parentName="p"></br>{`
`}{`공익 제보가 활성화되기 위해서는 내부 고발에 대한 사회적 인식이 우호적이어야하며, 내부 고발자를 보호하는 시스템이 갖춰져야 한다. 공익 제보에 관한 한국인 의 인식은 어떨까? 공익 제보가 정착되기 어렵게 만드는 요인은 무엇일까?`}</p>
    <h2>{`내부 고발자를 어떻게 평가해야 하는가?`}</h2>
    <ChartBase type={'pie'} data={props.data} columns={props.columns} mdxType="ChartBase"></ChartBase>
    <h3>{`공익 제보는 선한 일`}</h3>
    <p>{`한국인은 공의 제보를 훌륭한 일이라고 생각하고 공익 제보자를 칭찬받아야 할 인물로 존경하는 경향이 강하다. 전체적으로 내부 고발자를 우호적으로 평가하는데 특히 20~30대에서 내부 고발자에 대한 평가가 더 좋으며, 연령대가 높아질록 중립적인 의견이 더 높아지는 경향을 보인다.`}</p>
    <h3>{`내부 고발을 생각해본 적이 있습니까?`}</h3>
    <p>{`조사 결과 자주 한다 21.4%, 가끔 한다 47.8%, 전혀 없다 30.8%로 한 번 이상 내부 고발을 생각해본 사람의 비율은 실제 고발을 할 의향이 있다는 응답보다 더 높았다. 이것은 내부 고발에 대한 고민이 결심으로 이어지기 어려운 현실을 반영한다.`}</p>
    <h3>{`공익 제보에 대한 정책과 교육이 부족한 현실`}</h3>
    <p>{`조사 결과 내부 고발과 관련된 정책이나 교육 프로그램이 부족한 것으로 나타났다. 특히 회사 규모가 작을수록 내부 고발 관련 정책이나 교육이 부실한 경향을 보인다. 이러한 상황이 내부 고발을 불편하 고 꺼리게 만드는 한 요인인 것으로 추정된다.`}</p>
    <h6>{`*<세계일보>와 공공의창이 기획하고 여론조사기관 리서치DNA가 조사를 수행했다. 2017년 6월 7~8일 만 19세 이상 전국 성인남녀 1,000명을 대상으로 했다. 인구 비례 할당 후 RDD에 의한 무작위 추출로 표본을 추출해 ARS 무선전화 조사' 방식으로 진행했다. 오차범위는 95% 신뢰수준에 ±3.1%p, 응답률은 2.46%다.`}</h6>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      